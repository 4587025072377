import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
	palette: {
        primary: {
            main: "#2A4558",
            dark: "#04101f",
            light: "#d6dade"
        },
        secondary: {
            main: "#E9DE7C"
        },
    },
    body: {
        backgroundColor: "#2A4558",
        fontSize: 14,
    },
    typography: {
        fontFamily: ['"Saira"', '-apple-system', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        fontSize: 14,
        h1: {
            fontSize: '2.6rem',
            fontWeight: 500,
        },
        h2: {
            fontWeight: 300,
            fontSize: '3.65rem'
        },
        h3: {
            fontSize: '2rem',
            marginBottom: '1rem'
        },
        subtitle1: {
            fontSize: '0.875rem',
        }
    },
    text: {
        primary: '#fff',
        secondary: '#fff',
        disabled: '#fff',
        hint: '#fff',
        icon: '#fff',
    },
    props: {
        MuiTypography: {
            variantMapping: {
                subtitle1: 'h4',
                subtitle2: 'h5',
            }
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    color: "#fff",
                    backgroundColor: "#2A4558",
                    //fontSize: "1.0rem",
                },
            },
        },
    },
    MuiIconButton: {
        root: {
            color: '#fff',
        }
    },
    MuiSvgIcon: {
        root: {
            fill: '#fff',
        }
    },
})

theme = responsiveFontSizes(theme)

export default theme
