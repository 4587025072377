import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../src/theme'
import SEO from '../../src/components/seo'

export default function TopLayout(props) {
	
	return (
		<React.Fragment>
			<SEO title="Home" />
			<Helmet>
				<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
				<link href="https://fonts.googleapis.com/css2?family=Saira:wght@300;400;500;600&display=swap" rel="stylesheet" />
				<link href="https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap" rel="stylesheet" />
			</Helmet>
			<ThemeProvider theme={theme}>
				{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />
				{props.children}
			</ThemeProvider>
		</React.Fragment>
	)
}

TopLayout.propTypes = {
	children: PropTypes.node,
}
